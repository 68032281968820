<template>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">DropDownBox with embedded TreeView</div>
      <div class="dx-field-value">
        <DxDropDownBox
          v-model:value="treeBoxValue"
          :show-clear-button="true"
          :data-source="treeDataSource"
          value-expr="Id"
          display-expr="Name"
          placeholder="Select a value..."
          @value-changed="syncTreeViewSelection($event)"
        >
          <template #content="{}">
            <DxTreeView
              :ref="treeViewName"
              :data-source="treeDataSource"
              :select-by-click="true"
              :select-nodes-recursive="false"
              data-structure="plain"
              key-expr="Id"
              parent-id-expr="ParentId"
              selection-mode="multiple"
              show-check-boxes-mode="normal"
              display-expr="Name"
              @content-ready="syncTreeViewSelection($event)"
              @item-selection-changed="treeView_itemSelectionChanged($event)"
            />
          </template>
        </DxDropDownBox>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@cloudfun/core';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import DxTreeView from 'devextreme-vue/tree-view';
import CustomStore from 'devextreme/data/custom_store';
import createDataSource from '@/utils/devextreme';

export default {
  components: {
    DxDropDownBox,
    DxTreeView,
  },
  setup() {
    return {
      treeDataSource: ref(null),
      treeDataSource2: ref(null),
      treeBoxValue: ref(null),
      treeViewName: 'tree-view',
    };
  },
  created() {
    this.treeDataSource = createDataSource(this.$model, 'Id', 'productCategory/query');
    console.log(this.$model, this.treeDataSource)
    this.treeBoxValue = [1];
  },
  methods: {
    makeAsyncDataSource(jsonFile) {
      return new CustomStore({
        loadMode: 'raw',
        key: 'ID',
        load: function() {
          return fetch(`data/${ jsonFile}`)
            .then(response => response.json());
        }
      });
    },
    syncTreeViewSelection(e) {
      const treeView = (e.component.selectItem && e.component) || (this.$refs[this.treeViewName] && this.$refs[this.treeViewName].instance);

      if (treeView) {
        if (e.value === null) {
          treeView.unselectAll();
        } else {
          const values = e.value || this.treeBoxValue;
          values && values.forEach(function(value) {
            treeView.selectItem(value);
          });
        }
      }
    },
    treeView_itemSelectionChanged(e) {
      this.treeBoxValue = e.component.getSelectedNodeKeys();
    }
  }
};
</script>

<style scoped>
.dx-fieldset {
    height: 500px;
}
</style>
